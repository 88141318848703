import worksimplyApi from "../../services/worksimplyApi";
import { getQueryParams } from "../index";
import get from "lodash/get";
import { formatISO } from "date-fns";
import getSearchTimeWithBuffer from "../getSearchTimeWithBuffer";

export const getTimeZoneByCity = (c = null) => {
  const params = getQueryParams();
  const city = c || params.get("location") || "Toronto";

  switch (city) {
    case "Vancouver":
      return "America/Vancouver";
    case "Montreal":
      return "America/Montreal";
    default:
      return "America/Toronto";
  }
};

const getLocationsListMeetingRooms = async (cmsLocations) => {
  // Find the timeZone of the user
  const timezone = getTimeZoneByCity();

  const params = getQueryParams();

  const teamSize = params.get("team_size");

  const tz =
    params.get("location") === "Vancouver"
      ? "America/Vancouver"
      : "America/Toronto";

  const type = params.get("type");

  const start = params.get("start");
  const end = params.get("end");

  const defaultStart = `${formatISO(new Date())}`;
  // const defaultEnd = `${formatISO(addHours(new Date(), 1))}`;

  // Build Request
  const reqData = {
    from: getSearchTimeWithBuffer(start || defaultStart, timezone, -15),
    to: getSearchTimeWithBuffer(end, timezone, 15),
    teamSize: !teamSize || teamSize === "Team size" ? 1 : teamSize,
    soId: process.env.GATSBY_SO_ID,
  };

  const apiResponse = await worksimplyApi("meeting-rooms/search", reqData);

  if (apiResponse.message === "Success") {
    return apiResponse.meetingRooms
      .filter((score) => {
        return cmsLocations.find((l) => {
          return l.data.office_rnd_id === score.location.locationId;
        });
      })
      .map((score) => {
        const cmsLocation = cmsLocations.find((l) => {
          const matchingId = l.data.office_rnd_id === score.location.locationId;
          if (
            process.env.NODE_ENV === "development" ||
            process.env.GATSBY_STAGE === "development"
          ) {
            return matchingId && l.uid.indexOf("ws-test-") > -1;
          }
          return matchingId;
        });
        const descArray = get(score, "location.description", "").split("|");
        const latLngArray = get(descArray, "[1]", "").split(",");
        const meeting_rooms = get(score, "meeting_rooms", []).filter((mr) => {
          if (type === "daily" && !get(mr, "rates.dailyRate.price", false)) {
            return false;
          } else if (
            type === "hourly" &&
            !get(mr, "rates.hourlyRate.price", false)
          ) {
            return false;
          }
          return true;
        });
        return {
          ...score,
          meeting_rooms,
          hasMeetingRooms: meeting_rooms.length > 0,
          location: {
            ...score.location,
            lat: get(latLngArray, "[0]", 0).trim(),
            lng: get(latLngArray, "[1]", 0).trim(),
            description: get(descArray, "[0]", ""),
          },
          cmsLocation,
        };
      })
      .filter((score) => score.hasMeetingRooms);
  }

  return [];
};

export default getLocationsListMeetingRooms;

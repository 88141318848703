import * as React from "react";
import { useEffect, useState } from "react";
import { graphql, navigate } from "gatsby";
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";
import activeDocMeta from "../utils/activeDocMeta";
import Layout from "../../components/Layout";
import get from "lodash/get";
import LocationsProfileTitle from "../../components/LocationsProfileTitle/LocationsProfileTitle";
import getProfile from "../../utils/locationProfile/getProfile";
import { PrismicRichText } from "@prismicio/react";
import resizeImage from "../../services/resizeImage";
import ProductInfo from "../../components/Products/ProductInfo";
import LocationsProfileFeaturedAmenities from "../../components/LocationsProfileFeaturedAmenities/LocationsProfileFeaturedAmenities";
import LocationsProfileTeam from "../../components/LocationsProfileTeam/LocationsProfileTeam";
import LocationsProfileBoC from "../../components/LocationsProfileBoC/LocationsProfileBoC";
import LocationsProfileReviews from "../../components/LocationsProfileReviews/LocationsProfileReviews";
import LocationsProfileGallery from "../../components/LocationsProfileGallery/LocationsProfileGallery";
import LocationsProfileExplore from "../../components/LocationsProfileExplore/LocationsProfileExplore";
import StickyContact from "../../components/StickyContact/StickyContact";
import LocationsProfileModal from "../../components/LocationsProfileModal/LocationsProfileModal";
import getAvailabilities from "../../utils/locationProfile/getAvailabilities";
import getBusyTimes from "../../utils/locationProfile/getBusyTimes";
import FloorPlan from "../../components/FloorPlan";
import BookingComponent from "../../components/BookingComponent";
import { loadStripe } from "@stripe/stripe-js";

import { getQueryParams } from "../../utils";
import i18n from "../../utils/i18n";
import LocationProfileCta from "../../components/LocationProfileCta";
import resolvePrismicLink from "../../utils/resolvePrismicLink";
import { stripePk } from "../../components/VirtualOffices/Checkout";

const LocationTemplate = ({ data = {}, pageContext }) => {
  const pageData = get(data, "prismicLocation.dataRaw", {});

  const [modal, setModal] = useState(false);
  const [showContact, setShowContact] = useState(false);
  const [profile, setProfile] = useState({});
  const [selectedOption, setSelectedOption] = useState({});
  const [profileImages, setProfileImages] = useState([]);
  const [busyTimes, setBusyTimes] = useState([]);

  const toggleModal = (optionData = {}) => {
    setSelectedOption(optionData);
    setModal(!modal);
  };

  useEffect(() => {
    const url = window.location.hash;
    if (url === "#bookatour") {
      setModal(true);
    }
  }, []);

  useEffect(() => {
    const cmsImageSection = get(pageData, "body", []).find(
      (item) => item.slice_type === "location_images",
    );
    const cmsImages = get(cmsImageSection, "items", []).map((image) => {
      return {
        thumbnail: get(image, "image.thumbnail.url", null),
        image: get(image, "image.url", null),
      };
    });
    setProfileImages(cmsImages);
  }, [pageData]);

  useEffect(() => {
    if (!get(profile, "calendar_id", null)) return;
    getAvailabilities(get(profile, "calendar_id", null)).then(
      ({ events, calendar }) => {
        setBusyTimes(getBusyTimes(events, calendar));
      },
    );
  }, [profile]);

  useEffect(() => {
    getProfile(get(data, "prismicLocation.dataRaw.location_id", "")).then(
      (space) => {
        setProfile({
          ...space,
          hero_image_url: resizeImage(
            space?.hero_image_url,
            600,
            600,
            800,
            800,
            false,
          ),
        });
      },
    );
  }, []);

  const productInfo = get(pageData, "body", []).find(
    (item) => item.slice_type === "product_features",
  );

  const [stripePromise] = useState(
    loadStripe(
      stripePk[pageData?.office_rnd_id || "5b7d486a87daf911009c3014"],
      { locale: i18n.t("lang") === "" ? "en" : "fr" },
    ),
  );

  const [availableProducts, setAvailableProducts] = useState([]);

  useEffect(() => {
    const allSelectedProducts = get(pageData, "available_products", []);

    const allProducts = get(data, "allPrismicProductPages.nodes", []);

    const final = [];

    if (!allSelectedProducts || allSelectedProducts.length === 0) {
      return;
    }
    for (let i = 0; i < allSelectedProducts.length; i++) {
      const product = get(allSelectedProducts, `[${i}].product`, {});
      const productId = product.uid;
      const details = allProducts.find((p) => p.uid === productId);
      if (details) {
        final.push({
          value: get(details, "data.hero_section_title.text", ""),
          label: get(details, "data.hero_section_title.text", ""),
        });
      }
    }

    setAvailableProducts(final);
  }, [pageData]);

  const [isPageActive, setIsPageActive] = useState(false);

  useEffect(() => {
    setIsPageActive(true);
  }, [pageData]);

  const params = getQueryParams();

  const { link: redirectUrlAfterMeetingRoomBooking } = resolvePrismicLink(
    pageData?.redirect_url_after_meeting_room_reservation_form_submission || {},
  );

  const apiCity = get(profile, "city", null);
  const apiOfficeAddressCity = get(profile, "office_address.city", null);

  const showStickyBar = !!profile?.contact_name && !!profile?.user_image_url;

  const hasCalendar = !!profile?.calendar?.id;

  if (!isPageActive) return null;

  return (
    <Layout
      activeDocMeta={activeDocMeta(get(data, "prismicLocation", {}))}
      pages={{
        whyIqPages: get(data, "allPrismicWhyIqPages.nodes", []),
        productPages: get(data, "allPrismicProductPages.nodes", []),
        customPages: get(data, "allPrismicCustomPage.nodes", []),
        whyIqLandingPage: get(data, "allPrismicWhyIqLanding.nodes", []),
        productsLandingPage: get(
          data,
          "allPrismicProductsLandingPage.nodes",
          [],
        ),
      }}
      bookTour={() => setModal(true)}
      locationProfile={showStickyBar}
      subscriptionModalData={data?.prismicHomePage?.data || {}}
    >
      {params.get("profile_type") === "meeting-rooms" &&
        params.get("meeting_room_id") &&
        get(pageData, "stripe_connected_account_id", null) && (
          <BookingComponent
            stripePromise={stripePromise}
            back={async () => {
              const params = getQueryParams();
              const newParams = new URLSearchParams();

              let url = `/search-meeting-rooms?`;
              if (params.get("location")) {
                newParams.set("location", params.get("location") || "Toronto");
              }
              if (params.get("team_size")) {
                newParams.set("team_size", params.get("team_size"));
              }
              if (params.get("type")) {
                newParams.set("type", params.get("type"));
              }
              if (params.get("start") && params.get("end")) {
                newParams.set("start", params.get("start"));
                newParams.set("end", params.get("end"));
              }
              await navigate(`${url}${newParams.toString()}`);
            }}
            profile={profile}
            redirectUrlAfterMeetingRoomBooking={
              redirectUrlAfterMeetingRoomBooking
            }
          />
        )}
      <FloorPlan
        profile={profile}
        setSelectedOption={(value) => {
          toggleModal(value);
        }}
        busyTimes={busyTimes}
        cmsProfile={pageData}
        availableProducts={availableProducts}
      />

      <LocationsProfileModal
        option={1}
        profile={profile}
        cmsProfile={pageData}
        show={modal}
        setShow={setModal}
        close={() => {
          setModal(false);
        }}
        selectedOption={selectedOption}
        calendar={get(profile, "calendar", {})}
        busyTimes={busyTimes}
        selectedType={i18n.t("profilePage.BookATour")}
        availableProducts={availableProducts}
      />

      <LocationsProfileTitle
        slug={get(pageData, "slug", "")}
        carouselImages={profileImages}
        title={<PrismicRichText field={get(pageData, "location_title", [])} />}
        plainTitle={pageData?.location_title?.[0]?.text}
        descriptionHeader={get(pageData, "location_intro_title", "")}
        description={get(pageData, "location_intro_description", "")}
        city={apiCity || apiOfficeAddressCity}
        reviews={get(pageContext, "reviews", {})}
        address={get(pageData, "address", "")}
      />

      {productInfo && <ProductInfo slice={productInfo} fullWidth />}

      <LocationsProfileFeaturedAmenities
        carouselImages={get(pageData, "overview", []).map(
          (item) => item.image.url,
        )}
        carouselImagesTitles={get(pageData, "overview", []).map(
          (item) => item.description,
        )}
        modalTitle={get(pageData, "amenities_modal_title", "")}
        slices={get(pageData, "amenities", [])}
        modalButtonLabel={get(pageData, "more_button_label", "")}
        locationName={get(pageData, "location_title[0].text", [])}
        locationCity={get(profile, "city", "")}
        cmsData={pageData}
      />
      <LocationsProfileExplore
        setBookTourModalShow={hasCalendar ? () => toggleModal() : null}
        data={pageData}
        lat={get(profile, "office_address.lat", 0)}
        lng={get(profile, "office_address.lng", 0)}
        address={get(profile, "office_address.address", "")}
      />
      <LocationsProfileGallery
        data={get(pageData, "body", []).find(
          (item) => item.slice_type === "featured_images",
        )}
      />
      <LocationsProfileReviews
        data={get(pageContext, "reviews", {})}
        profile={pageData}
      />

      <LocationsProfileTeam
        team={get(pageData, "body", []).find(
          (item) => item.slice_type === "dedicated_team",
        )}
      />
      <LocationsProfileBoC
        data={get(pageData, "body", []).find(
          (item) => item.slice_type === "footer_cta",
        )}
      />

      <LocationProfileCta
        data={get(pageData, "body", []).find(
          (item) => item.slice_type === "footer_cta_with_2_blocks",
        )}
      />

      <StickyContact
        profile={showStickyBar ? profile : {}}
        cmsProfile={pageData}
        showModal={
          profile?.calendar?.id
            ? () => {
                setModal(true);
              }
            : null
        }
      />
    </Layout>
  );
};

export const query = graphql`
  query LocationPageTemplate($lang: String, $uid: String) {
    soData {
      slug
      apiLocations {
        id
        stripe_connected_account_id
      }
    }
    prismicHomePage(lang: { eq: $lang }) {
      _previewable
      data {
        subscription_form_title
        subscription_form_description
        subscription_form_button_label
        subscription_footer_button_label
        blog_page_modal_display_delay
      }
    }
    allPrismicProductPages(
      filter: { lang: { eq: $lang } }
      sort: { fields: data___order, order: ASC }
    ) {
      nodes {
        id
        url
        uid
        _previewable
        data {
          hero_section_title {
            text
          }
          menu_link_name
          menu_link_description
          product_type
          display_in_header_menu
        }
      }
    }
    allPrismicWhyIqLanding(filter: { lang: { eq: $lang } }) {
      nodes {
        _previewable
        id
        lang
        type
        url
        uid
        alternate_languages {
          id
          uid
          type
          lang
        }
        dataRaw
      }
    }
    allPrismicBlogPost(
      filter: { lang: { eq: $lang } }
      limit: 6
      sort: { fields: data___post_date, order: DESC }
    ) {
      nodes {
        data {
          title
          summary
          post_date(formatString: "MMM DD, yyyy")
          cover_image {
            localFile {
              url
              childImageSharp {
                gatsbyImageData(width: 800, formats: AUTO, height: 450)
              }
            }
          }
        }
        uid
        lang
      }
    }
    allPrismicProductsLandingPage(filter: { lang: { eq: $lang } }) {
      nodes {
        _previewable
        id
        lang
        type
        url
        uid
        alternate_languages {
          id
          uid
          type
          lang
        }
        dataRaw
      }
    }
    allPrismicWhyIqPages(
      filter: { lang: { eq: $lang } }
      sort: { fields: data___order, order: ASC }
    ) {
      nodes {
        id
        url
        uid
        type
        _previewable
        data {
          display_in_main_menu
          menu_link_name
          menu_link_description
          menu_link_image {
            url
            localFile {
              url
              childImageSharp {
                gatsbyImageData(width: 192, formats: AUTO, height: 128)
              }
            }
          }
        }
      }
    }
    allPrismicCustomPage(filter: { lang: { eq: $lang } }) {
      nodes {
        id
        uid
        lang
        type
        url
        dataRaw
        data {
          main_menu_order
          link_label
          show_in_main_menu
        }
      }
    }
    prismicLocation(lang: { eq: $lang }, uid: { eq: $uid }) {
      id
      uid
      lang
      url
      type
      _previewable
      dataRaw
      alternate_languages {
        id
        uid
        type
        lang
      }
      data {
        meta_title
        meta_description
        meta_image {
          url
        }
      }
    }
  }
`;

export default withPrismicPreview(LocationTemplate);

import React, { useEffect, useState } from "react";
import { GoogleMap, Marker } from "../GoogleMap";
import {
  content,
  content__transportation_type,
  content__transportation_type__list,
  content__transportation_type__list__item,
  lpeSection,
  lpeSection__contentSection,
  lpeSection__ctaSection,
  lpeSection__customSection,
  lpeSection__tabSection,
  lpeSection__title,
} from "./LocationsProfileExplore.module.scss";
import * as s from "./LocationsProfileExplore.module.scss";
import get from "lodash/get";
import { Button, Tab, Col, Row, Nav } from "react-bootstrap";
import i18n from "../../utils/i18n";

const LocationsProfileExplore = ({
  setBookTourModalShow,
  data,
  lat,
  lng,
  address,
}) => {
  const [stateActiveKey, setStateActiveKey] = useState("0");

  const floorPlan = get(data, "floor_plans_pdf.url", null);
  const virtualTour = get(data, "virtual_tour_link.url", null);

  const listWithDesc = get(data, "explore", []).find(
    (item) => item.slice_type === "transport_list_with_desc",
  );
  const listWithoutDesc = get(data, "explore", []).find(
    (item) => item.slice_type === "transport_list_without_desc",
  );
  if (!lat || !lng) return null;
  return (
    <div className={`${lpeSection}`}>
      <div className={`container-xxl`}>
        <div className={`row`}>
          <h2 className={lpeSection__title}>{i18n.t("Explore")}</h2>
          <div className={`col-lg-8 offset-lg-2 col-md-10 offset-md-1`}>
            <Tab.Container
              id="explore_section"
              defaultActiveKey={0}
              onSelect={(eventKey) => {
                setStateActiveKey(eventKey);
              }}
            >
              <Row>
                <Col sm={12}>
                  <Nav className={lpeSection__tabSection}>
                    <Nav.Item>
                      <Nav.Link
                        className={
                          stateActiveKey === "0"
                            ? s.lpeSection__tabSection__selected
                            : ""
                        }
                        as={"button"}
                        eventKey={0}
                      >
                        <h5>
                          {get(
                            data,
                            "explore_section___map_section_label",
                            "Map",
                          ) || "Map"}
                        </h5>
                      </Nav.Link>
                    </Nav.Item>
                    {get(
                      data,
                      "explore_section___transportation_section_label",
                      "",
                    ) && (
                      <Nav.Item>
                        <Nav.Link
                          className={
                            stateActiveKey === "1"
                              ? s.lpeSection__tabSection__selected
                              : ""
                          }
                          as={"button"}
                          eventKey={1}
                        >
                          <h5>
                            {get(
                              data,
                              "explore_section___transportation_section_label",
                              "",
                            )}
                          </h5>
                        </Nav.Link>
                      </Nav.Item>
                    )}
                    {virtualTour && (
                      <Nav.Item>
                        <Nav.Link
                          className={
                            stateActiveKey === "2"
                              ? s.lpeSection__tabSection__selected
                              : ""
                          }
                          as={"button"}
                          eventKey={2}
                        >
                          <h5>
                            {get(
                              data,
                              "explore_section___virtual_tour_section_label",
                              "Virtual Tour",
                            )}
                          </h5>
                        </Nav.Link>
                      </Nav.Item>
                    )}
                    {floorPlan && (
                      <Nav.Item>
                        <Nav.Link
                          href={floorPlan}
                          target={"_blank"}
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          <h5>
                            {get(
                              data,
                              "explore_section___floor_plan_section_label",
                              "Floor Plan",
                            )}
                          </h5>
                        </Nav.Link>
                      </Nav.Item>
                    )}
                  </Nav>
                </Col>
                <Col sm={12}>
                  <Tab.Content className={s.tabContentMobileStyles}>
                    <Tab.Pane eventKey={0}>
                      <div
                        className={`${lpeSection__contentSection} ${lpeSection__customSection}`}
                      >
                        <div
                          className={
                            "position-absolute h-100 w-100 top-0 left-0"
                          }
                        >
                          {lat && lng && (
                            <GoogleMap
                              center={{ lat: lat, lng: lng }}
                              defaultZoom={13}
                            >
                              <Marker
                                name={get(data, "location_title[0].text", "")}
                                lat={lat}
                                lng={lng}
                              />
                            </GoogleMap>
                          )}
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey={1}>
                      <div className={`${lpeSection__contentSection}`}>
                        {/*TRANSPORTATION START*/}
                        <div className={`container`}>
                          <div className={`row justify-content-center`}>
                            <div className={`col-sm-5`}>
                              <h6 className={"mb-4"}>
                                {get(listWithDesc, "primary.title", "")}
                              </h6>
                              {get(listWithDesc, "items", []).map(
                                (item, idx) => (
                                  <div key={idx} className={`d-flex mb-3`}>
                                    <img
                                      alt=""
                                      src={get(item, "icon.url", "")}
                                      className={`me-3`}
                                    />
                                    <p>
                                      {get(item, "text", "")} <br />
                                      <i>{get(item, "description", "")}</i>
                                    </p>
                                  </div>
                                ),
                              )}
                            </div>
                            <div className={`col-sm-5`}>
                              <h6 className={"mb-4"}>
                                {get(listWithoutDesc, "primary.title", "")}
                              </h6>
                              {get(listWithoutDesc, "items", []).map(
                                (item, idx) => (
                                  <div key={idx} className={`d-flex mb-3`}>
                                    <img
                                      // delete the width & height if they can agree that these will be handled from the CMS
                                      width={32}
                                      height={32}
                                      alt=""
                                      src={get(item, "icon.url", "")}
                                      className={`me-3`}
                                    />
                                    <p>{get(item, "text", "")}</p>
                                  </div>
                                ),
                              )}
                            </div>
                          </div>
                        </div>
                        {/*TRANSPORTATION END*/}
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey={2}>
                      <div
                        className={`${lpeSection__contentSection} ${lpeSection__customSection}`}
                      >
                        <iframe src={virtualTour} />
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </div>
        </div>
      </div>

      {/*<div className={cx(lpeSection__ctaSection, `container-xxl`)}>*/}
      {/*  <div className={`row`}>*/}
      {/*    <div*/}
      {/*      className={cx(*/}
      {/*        `col-md-10 offset-md-1`,*/}
      {/*        `d-flex flex-lg-column text-center`,*/}
      {/*        `justify-content-between align-items-center flex-md-row`,*/}
      {/*        `flex-column`*/}
      {/*      )}*/}
      {/*    >*/}
      {/*      <p className={`text-white`}>{get(data, "book_tour_text", "")}</p>*/}
      {/*      <div>*/}
      {/*        <Button*/}
      {/*          variant={"outline-light"}*/}
      {/*          onClick={() => setBookTourModalShow(true)}*/}
      {/*        >*/}
      {/*          <span>*/}
      {/*            {get(data, "book_tour_button_label", "") || "Book a Tour"}*/}
      {/*          </span>*/}
      {/*        </Button>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</div>*/}
      <div
        className={`container-xxl [ d-flex flex-column text-center ] ${lpeSection__ctaSection}`}
      >
        <p className={`text-white`}>{get(data, "book_tour_text", "")}</p>
        {typeof setBookTourModalShow === "function" && (
          <div>
            <Button
              variant={"outline-light"}
              onClick={() => setBookTourModalShow(true)}
            >
              <span>
                {get(data, "book_tour_button_label", "") || "Book a Tour"}
              </span>
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default LocationsProfileExplore;

import React from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import {
  seButton,
  seButtonItem,
  seContainer,
} from "../DropdownDatePicker.module.scss";
import {
  add,
  addHours,
  format,
  isAfter,
  isBefore,
  isSameMinute,
  isValid,
} from "date-fns";
import getTimeSlots from "./getTimeSlots";
import i18n from "../../../utils/i18n";
import { convertDateTimeToMeetingRoomTz } from "../../BookingComponent/getMeetingRoomReservationData";
import { getTimeZoneByCity } from "../../../utils/locationsRequests/getLocationsListMeetingRooms";

const Timepicker = ({
  date = null,
  endDate = null,
  setStart = () => null,
  setEnd = () => null,
  location,
}) => {
  const startTimeSlots = getTimeSlots(date, { pop: true });
  const endTimeSlots = getTimeSlots(date, { shift: true });

  console.log(location, getTimeZoneByCity(location));

  console.log(
    format(
      convertDateTimeToMeetingRoomTz(new Date(), getTimeZoneByCity(location)),
      "HH:mm:ss",
    ),
  );

  return (
    <>
      <div className={seContainer}>
        <div className={`w-100`}>
          <DropdownButton
            title={
              isValid(date) && startTimeSlots.find((d) => isSameMinute(d, date))
                ? format(date, "hh:mm a")
                : i18n.t("Start")
            }
            className={seButton}
          >
            {!date || !isValid(date) ? (
              <Dropdown.Item value="" disabled as={"div"}>
                {i18n.t("PleasePickADateFirst")}
              </Dropdown.Item>
            ) : (
              startTimeSlots.map((time, index) => {
                const currentZoneTime = convertDateTimeToMeetingRoomTz(
                  time,
                  getTimeZoneByCity(location),
                );
                return (
                  <Dropdown.Item
                    key={index}
                    as={"div"}
                    className={seButtonItem}
                    onClick={() => {
                      setStart(time);
                      setEnd("");
                    }}
                    disabled={isAfter(addHours(new Date(), 1), currentZoneTime)}
                  >
                    {format(time, "hh:mm a")}
                  </Dropdown.Item>
                );
              })
            )}
          </DropdownButton>
        </div>
        {/*<div>*/}
        {/*  <img alt="right arrow" src={ArrowRight} width={"25"} />*/}
        {/*</div>*/}
        <div className={`w-100`}>
          <DropdownButton
            title={
              isValid(endDate) &&
              endTimeSlots.find((d) => isSameMinute(d, endDate))
                ? format(endDate, "hh:mm a")
                : i18n.t("End")
            }
            className={seButton}
          >
            {!date || !isValid(date) ? (
              <Dropdown.Item value="" disabled as={"div"}>
                {i18n.t("PleasePickADateFirst")}
              </Dropdown.Item>
            ) : (
              endTimeSlots.map((time, index) => (
                <Dropdown.Item
                  key={index}
                  as={"div"}
                  className={seButtonItem}
                  onClick={() => setEnd(time)}
                  disabled={isBefore(time, add(date, { hours: 1 }))}
                >
                  {format(time, "hh:mm a")}
                </Dropdown.Item>
              ))
            )}
          </DropdownButton>
        </div>
      </div>
    </>
  );
};

export default Timepicker;
